import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyAOYec08IsuJDprPyWypFqhB3y5wK3NRL4',
  authDomain: 'foragerun.firebaseapp.com',
  databaseURL: 'https://foragerun.firebaseio.com',
  projectId: 'foragerun',
  storageBucket: 'foragerun.appspot.com',
  messagingSenderId: '471426982919',
  appId: '1:471426982919:web:152f1afb1411673b',
};

firebase.initializeApp(config);

export default firebase;
