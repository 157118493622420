import React, { Component } from 'react';
import { Box, Image } from 'grommet';

class Card extends Component {
  render() {
    return (
      <Box
        background="white"
        elevation="medium"
        margin="medium"
        height="medium"
        pad="medium">
        <Box>
          <Image src="https://picsum.photos/200/300" />
        </Box>
        <p>{this.props.foodTruckName}</p>
      </Box>
    );
  }
}

export default Card;
