import React, { Component } from 'react';
import {
  Box,
  Button,
  Collapsible,
  Grommet,
  Heading,
  Layer,
  ResponsiveContext,
  TextInput,
} from 'grommet';
import { FormClose, Notification } from 'grommet-icons';
import CardList from './components/Card/CardList';

const theme = {
  global: {
    colors: {
      brand: '#E0BE0F',
    },
    font: {
      family: 'Roboto',
      size: '14px',
      height: '20px',
    },
  },
};

const AppBar = props => (
  <Box
    tag="header"
    direction="row"
    align="center"
    justify="between"
    background="brand"
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation="medium"
    style={{ zIndex: '1' }}
    {...props}
  />
);

class App extends Component {
  state = {
    showSidebar: false,
  };

  render() {
    const { showSidebar } = this.state;
    return (
      <Grommet theme={theme} full>
        <ResponsiveContext.Consumer>
          {size => (
            <Box fill>
              <AppBar>
                <Heading level="3" margin="none" color="light-1">
                  ForageRun
                </Heading>
                <Button
                  icon={<Notification />}
                  color="light-1"
                  onClick={() =>
                    this.setState({ showSidebar: !this.state.showSidebar })
                  }
                />
              </AppBar>

              <Box direction="row" flex overflow={{ horizontal: 'hidden' }}>
                <Box flex>
                  <Box flex pad="medium" margin={{ bottom: 'medium' }}>
                    <TextInput placeholder="Search" />
                  </Box>
                  <CardList />
                </Box>

                {!showSidebar || size !== 'small' ? (
                  <Collapsible direction="horizontal" open={showSidebar}>
                    <Box
                      width="medium"
                      background="light-2"
                      elevation="small"
                      align="center"
                      justify="center"
                      basis="auto"
                      fill="vertical">
                      sidebar
                    </Box>
                  </Collapsible>
                ) : (
                  <Layer>
                    <Box
                      background="light-2"
                      tag="header"
                      justify="end"
                      align="center"
                      direction="row">
                      <Button
                        icon={<FormClose />}
                        onClick={() => this.setState({ showSidebar: false })}
                      />
                    </Box>
                    <Box
                      fill
                      background="light-2"
                      align="center"
                      justify="center">
                      sidebar
                    </Box>
                  </Layer>
                )}
              </Box>
            </Box>
          )}
        </ResponsiveContext.Consumer>
      </Grommet>
    );
  }
}

export default App;
