import React, { Component } from 'react';
import { Grid, ResponsiveContext } from 'grommet';
import firebase from '../../firebase';
import Card from './Card';

class CardList extends Component {
  constructor(props) {
    super(props);
    this.ref = firebase.firestore().collection('foodtrucks');
    this.unsubscribe = null;
    this.state = {
      foodtrucks: [],
    };
  }

  collection = querySnapshot => {
    const foodtrucks = [];
    querySnapshot.forEach(doc => {
      const { name } = doc.data();
      foodtrucks.push({
        key: doc.id,
        doc,
        name,
      });
    });
    this.setState({
      foodtrucks,
    });
  };

  componentDidMount() {
    this.unsubscribe = this.ref.onSnapshot(this.collection);
  }

  render() {
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Grid
            align="start"
            columns={
              size !== 'small'
                ? { count: 'fill', size: 'medium' }
                : { size: 'small' }
            }
            gap="medium">
            {this.state.foodtrucks.map(foodtruck => (
              <Card key={foodtruck.key} foodTruckName={foodtruck.name} />
            ))}
          </Grid>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

export default CardList;
